<template>
  <FocusTrap>
    <div id="fueltankdetail" style="padding: 5px;" >
      <div class="row" >
        <table id="tanktable" class="table table-bordered table-columned" >
        <thead style="background-color: lightgrey">
          <tr>
            <th style="width:50px;">S.No</th>
            <th >Tank</th>
            <th style="width:75px; text-align: right;">Qty</th>
            <th style="width:120px; text-align: right;">Before Dip</th>
            <th style="width:120px; text-align: right;">Before Qty</th>
            <th style="width:120px; text-align: right;">After Dip</th>
            <th style="width:120px; text-align: right;">After Qty</th>
            <th style="width:120px; text-align: right;">Diff Qty</th>
            <th style="width: 30px;padding: 0px;">Action</th>
          </tr>
        </thead>
          <tbody v-for="(tankdetail,index) in detail.tankdetails" >
            <tr style="padding: 0px;">
              <td style="padding: 0px;text-align: center;">{{ index + 1}}</td>
              <td style="padding: 0px;">
                <select name="cmbTank" class="form-control tank"  style="padding: 0px;" v-model="tankdetail.tank.id" @change="onItemChanged(tankdetail.tank.id)"  autofocus  aria-required="true" >
                  <option v-for="tank in tanks" :value="tank.id">{{tank.name}}</option>
                </select>
              </td>
              <td style="padding: 0px;">
                <input type="number" class="form-control qty" style="padding: 0px;" v-model="tankdetail.qty" @change="rowValueEqualizer(index, 2)">
              </td>
              <td style="padding: 0px;">
                <input type="number" class="form-control opendip" style="padding: 0px;" v-model="tankdetail.opendip" @change="rowValueEqualizer(index, 3)">
              </td>
              <td style="padding: 0px;" >
                <input type="number" class="form-control openqty"  style="padding: 0px;" v-model="tankdetail.openqty" @change="rowValueEqualizer(index, 4)">
              </td>
              <td style="padding: 0px;" >
                <input type="number" class="form-control closedip" style="padding: 0px;" v-model="tankdetail.closedip" @change="rowValueEqualizer(index, 5)">
              </td>
              <td style="padding: 0px;" >
                <input type="number" class="form-control closeqty" style="padding: 0px;" v-model="tankdetail.closeqty" @change="rowValueEqualizer(index, 6)">
              </td>
              <td style="padding: 0px;">
                <input type="number" class="form-control diffqty" style="padding: 0px;" v-model="tankdetail.diffqty" @change="rowValueEqualizer(index, 7)">
              </td>

              <td  class="pull-right" style="padding: 0px; padding-right: 10px; width: 50px;" tabindex="-1">
                <button class="btn btn-outline-info"  tabindex="-1" @click="removeTankRow" >X</button>
              </td>

            </tr>
          </tbody>
          <tfoot style="background-color: whitesmoke;">
          <tr style="padding: 0px;">
            <td style="padding: 0px;text-align: center;">
              <button type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow" >
                <i class="icon-plus3"></i>
              </button>
            </td>
            <td style="padding: 0px;">
            </td>
            <td style="padding: 0px;text-align: right;">
              {{tank_fuel_total}}
            </td>
            <td style="padding: 0px;">
            </td>
            <td style="padding: 0px;" >
            </td>
            <td style="padding: 0px;" >
            </td>
            <td style="padding: 0px;" >
            </td>
            <td style="padding: 0px;">
            </td>
            <td  class="pull-right" style="padding: 0px; padding-right: 10px; width: 50px;" tabindex="-1">
            </td>
          </tr>
          </tfoot>
      </table>
      </div>
      <div class="row pull-right" style="padding-right: 20px; padding-bottom: 10px; padding-top: 10px;" >
        <button id="btnUpdate" type="button" class="btn btn-outline-info" @click="update_tank_row">Update</button>
      </div>
  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'FuelPurchaseTankDetail',
    components: {  },
    store,
    props: {
      mydetail: {
        type: Object,
        default: () => JSON.parse('{"idx":0,"item":{"_id":"","code":0,"name":"","unit":6},"unit":2,"rate":0,"qty":0,"tankdetails":[],"taxable_amt":0,"dly_amt":0,"tcs_amt":0,"others_amt":0,"tax_rate":0,"tax_amt":0,"net_amt":0}')
      },
      tanks: {
        type: Array,
        default: () => []
      },
      myindex: {
        type: Number,
        default: () => 0
      },
    },
    beforeMount () {
      const self = this;
      self.detail = this.$props.mydetail;

    },
    data () {
      return {
        index : 0,
        tank_fuel_total: 0,
        tankDetails: JSON.parse('{"idx":0,"tank":{"id":0,"name":""},"qty":0,"opendip":0,"closedip":0,"openqty":0,"closeqty":0,"diffqty":0}'),
        detail: JSON.parse('{"idx":0,"item":{"_id":"","code":0,"name":"","unit":6},"unit":2,"rate":0,"qty":0,"tankdetails":[],"taxable_amt":0,"dly_amt":0,"tcs_amt":0,"others_amt":0,"tax_rate":0,"tax_amt":0,"net_amt":0}'),
      }
    },
    created () {
      const self = this;

    },
    mounted () {
      const self = this;

      if(self.detail.tankdetails.length == 0) {

        self.detail.tankdetails.push({"idx":0,"tank":{"id":0,"name":""},"qty":0,"opendip":0,"closedip":0,"openqty":0,"closeqty":0,"diffqty":0});

        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) select").focus();
        }, 99);
      }

    },
    methods: {
      movefocus (evt) {

        let keycode = (evt.keyCode ? evt.keyCode : evt.which)

        if (keycode == '13') {

          evt.preventDefault()
          // Get all focusable elements on the page

          let $canfocus = $(':focusable')
          // $canfocus = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

          let index = $canfocus.index(document.activeElement) + 1
          if (index >= $canfocus.length) index = 0
          $canfocus.eq(index).focus()
        } else {

        }
      },
      addRow(){
        const self = this;
        self.detail.tankdetails.push({"idx":0,"tank":{"id":0,"name":""},"qty":0,"opendip":0,"closedip":0,"openqty":0,"closeqty":0,"diffqty":0});
        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) select").focus();
        }, 99);

      },
      displayTotal(){
        const self = this;
        try {
          self.tank_fuel_total = 0;
          self.detail.tankdetails.forEach((tankrow) => {
            self.tank_fuel_total +=  parseFloat(tankrow.qty);
          });
        }catch (e) {
          alert(e);
        }
      },
      onItemChanged(tank_id){
      },
      removeTankRow(idx){
        const self = this;

        self.displayTotal();
      },
      rowValueEqualizer(row, col){
        const self = this;

        switch (col) {
          case 0:
            break;
          case 1:

            break;
          case 2:
            self.displayTotal();
            break;
        }
      },
      update_tank_row(){
        const self = this;

        if( parseInt(self.tank_fuel_total) !== parseInt(this.detail.qty)) {
          alert("Invalid Tank BreakUp");
          return;
        }

        this.$emit('tankdetail_saved', self.detail);
      },
    }

  }
</script>

<style scoped>
  input {
    text-align: right;
  }

  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00!important;
    color: #0a0a0a!important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

</style>
