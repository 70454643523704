<template>
  <tr style="padding: 0px;">
    <td style="padding: 0px;text-align: center;"> {{ myindex + 1}} </td>

    <td style="padding: 0px;">
      <select class="form-control" v-if="detail" @change="onItemChanged" >
        <option v-for="item in fuelitems" v-bind:value="item.id">
          {{ item.name }}
        </option>
      </select>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" @change="rowValueEqualizer(index, 2)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.rate" @change="rowValueEqualizer(index, 3)" />
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.taxable_amt" @change="rowValueEqualizer(index, 4)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.dly_amt" @change="rowValueEqualizer(index, 5)"/>
    </td>

    <td style="padding: 0px; width: 30px;" >
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.tax_rate" @change="rowValueEqualizer(index, 6)" />
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.tax_amt"  @change="rowValueEqualizer(index, 7)"/>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.net_amt" readonly />
    </td>

    <td style="padding: 0px;text-align: right;">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>

    <vmodal name="tankbreakup_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="77%" height="325px" >
      <form>
        <div class="card">
          <FuelPurchaseTankDetail :tanks="tanks" :myindex="index" :mydetail="detail" v-on:tankdetail_saved="tankbreakeup_saved" v-on:onRemoveRow="removeTankRow"></FuelPurchaseTankDetail>
        </div>
      </form>
    </vmodal>

  </tr>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import numeral from 'numeral'
import FuelPurchaseTankDetail from './FuelPurchaseTankDetail'

export default {
  name: 'FuelInvoiceDetailRow',
  store,
  components: {
    FuelPurchaseTankDetail
  },
  props: {
    myrow: {
      type: Object,
      default: () => JSON.parse('{"idx":0,"item":{"id":0,"name":"","unit":6},"unit":2,"rate":0,"qty":0,"taxable_amt":0,"dly_amt":0,"tax_rate":0,"tax_amt":0,"net_amt":0}')
    },
    myindex: {
      type: Number,
      default: () => 0
    },
    items: {
      type: Array,
      default: () => []
    },
    tanks: {
      type: Array,
      default: () => new Array()
    },
    state: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      detail: JSON.parse('{"idx":0,"item":{"id":0,"name":"","unit":6},"unit":2,"rate":0,"qty":0,"taxable_amt":0,"dly_amt":0,"tax_rate":0,"tax_amt":0,"net_amt":0}'),
      index: 0,
      fuelitems : [],
    }
  },
  beforeMount () {
    const self = this;

    this.fuelitems = this.items;
    this.detail = this.myrow;
    this.index = this.myindex;

    this.detail.taxable_amt = this.detail.taxable_amt.toFixed(2)
    this.detail.rate = this.detail.rate.toFixed(2);
    this.detail.dly_amt  = this.detail.dly_amt.toFixed(2);
    this.detail.tax_amt = this.detail.tax_amt.toFixed(2);
    this.detail.net_amt = this.detail.net_amt.toFixed(2);
  },
  mounted () {
    const self = this;

    // console.log( JSON.stringify(this.fuelitems));

  },
  methods: {
    onItemChanged (item) {
      const self = this;
      // self.$data.detail.item = item;
      self.rowValueEqualizer(self.$data.detail.idx, 1);
    },
    removeRow (idx) {
      this.$emit('onRemoveRow', idx)
    },
    removeTankRow(idx){

    },
    tankbreakeup_saved(detail){
      const self = this;
      self.$modal.hide('tankbreakup_window');

    },
    calculate_tax (detail) {
      let taxrate = detail.tax_rate;

      let tax_value = Math.round( ((detail.qty * detail.rate) + parseFloat(detail.dly_amt))  * (taxrate / 100) * 100);
      detail.tax_amt = tax_value / 100;

    },
    rowValueEqualizer (rowIndex, colIndex) {
      const self = this;
      let tax_value = 0;

      let detail = self.$data.detail
      switch (colIndex) {
        case 1://Item
          break
        case 2:// Product Qty
          detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00')
          self.calculate_tax(detail)
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.dly_amt) + parseFloat(detail.tax_amt)).format('0.00');
          self.showTankDetail();
          break

        case 3:// Product Rate
          detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00')

          self.calculate_tax(detail)
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.dly_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break
        case 4:// Taxable value
          self.calculate_tax(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.dly_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break;
        case 5:// Delivery Charges
          self.calculate_tax(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.dly_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break;
        case 6: // Tax Rate
          self.calculate_tax(detail);
          detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.dly_amt) + parseFloat(detail.tax_amt)).format('0.00');
          break
        case 7: //Net Amount
          detail.tax_amt = numeral(parseFloat(detail.tax_amt) );
          // detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00')
          break
      }
      self.$emit('onrow_change', rowIndex, colIndex)
    },
    showTankDetail(){
      this.$modal.show('tankbreakup_window');
    },

  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00!important;
    color: #0a0a0a!important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
